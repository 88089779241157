<template>
  <div
    class="bg-secondary d-flex flex-column justify-content-center text-center"
  >
    <div v-if="num == 1" class="app-content2 content">
      <div class="app-content2 content py-2">
        <div>
          <b-img
            :src="footerData.logo"
            rounded
            fluid
            alt="logo"
            class="footer-img my-2"
          />
        </div>

        <div class="rounded position-relative">
          <div>
            <b-card-title
              v-show="
                footerData.en_description != null ||
                footerData.ar_description != null
              "
              class="mb-25 font-weight-bold color-dark"
            >
              {{
                getCookie("lang") === "en"
                  ? "About the restaurant"
                  : "حول المطعم "
              }}
            </b-card-title>
          </div>

          <div>
            <div
              class="mb-0"
              v-html="
                getCookie('lang') === 'en'
                  ? footerData.en_description
                  : footerData.ar_description
              "
            />
          </div>

          <div class="mt-1">
            <b-card-title
              v-if="footerData.open_at != null || footerData.close_at != null"
              class="mb-25 font-weight-bold color-dark"
            >
              {{ getCookie("lang") === "en" ? "Time" : "  أوقات الدوام" }}
            </b-card-title>

            <!-- <div>{{ footerData.open_at }} - {{ footerData.close_at }}</div> -->
            <div
              v-if="footerData.open_at != null || footerData.close_at != null"
            >
              {{ getCookie("lang") === "en" ? "From" : "من" }}
              {{ footerData.open_at }}
              {{ getCookie("lang") === "en" ? "To" : "الى" }}
              {{ footerData.close_at }}
              {{
                getCookie("lang") === "en"
                  ? footerData.en_time_description
                  : footerData.ar_time_description
              }}
            </div>
          </div>

          <div class="mt-1">
            <b-card-title
              v-if="footerData.en_address != null || footerData.address != null"
              class="mb-25 font-weight-bold color-dark"
            >
              {{ getCookie("lang") === "en" ? "Address" : "العنوان" }}
            </b-card-title>

            <b-card-text class="mb-0">
              {{
                getCookie("lang") === "en"
                  ? footerData.en_address
                  : footerData.address
              }}
            </b-card-text>
          </div>

          <div class="mt-1">
            <b-card-title class="mb-25 font-weight-bold color-dark">
              {{
                getCookie("lang") === "en"
                  ? "Social Media"
                  : "  روابطنا على التواصل الاجتماعي"
              }}
            </b-card-title>
          </div>

          <div>
            <b-card-text class="mt-1 mb-0">
              <div class="d-flex flex-wrap my-gap justify-content-center">
                <div
                  v-if="socialmedia.twitter"
                  class="mysvg"
                  @click="redirect(socialmedia.twitter)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="19.201"
                    viewBox="0 0 24 19.201"
                  >
                    <path
                      id="icons8_Twitter"
                      d="M26,4A15.79,15.79,0,0,1,22.082,5.53c-.032.008-.059.019-.09.028A5.4,5.4,0,0,0,12.8,9.4c0,.157-.013.446,0,.6A11.862,11.862,0,0,1,3.518,5.2a7.307,7.307,0,0,0-.343,2.438c0,1.681,1.314,3.332,3.36,4.356a4.919,4.919,0,0,1-1.224.167A3.223,3.223,0,0,1,3.2,11.42v.061c0,2.35,2.494,3.949,4.711,4.394a4.3,4.3,0,0,1-1.8.292c-.312,0-1.416-.143-1.711-.2.617,1.926,2.842,3.008,4.962,3.047C7.7,20.317,6.553,20.8,3.157,20.8H2a14.546,14.546,0,0,0,7.616,2.4C18.532,23.2,23.6,16.4,23.6,10c0-.1,0-.319-.006-.536,0-.022.006-.042.006-.064s-.01-.064-.01-.1c0-.163-.007-.316-.011-.395A9.861,9.861,0,0,0,26,6.4a9.684,9.684,0,0,1-2.784.763A7.269,7.269,0,0,0,26,4Z"
                      transform="translate(-2 -3.999)"
                      fill="#272c30"
                    />
                  </svg>
                </div>
                <div
                  v-if="socialmedia.snapchat"
                  class="mysvg"
                  @click="redirect(socialmedia.snapchat)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="22.799"
                    viewBox="0 0 24 22.799"
                  >
                    <path
                      id="icons8_snapchat"
                      d="M14,2C6.8,2,6.8,9.2,6.8,9.2v1.2H6.724c-.408,0-2.324.084-2.324,1.675C4.4,13.927,6.436,14,6.758,14H6.8c0,1.2-1.982,2.605-3.283,2.93A2.041,2.041,0,0,0,2,18.811a2,2,0,0,0,.127.772,3.3,3.3,0,0,0,3.113,1.61A2.26,2.26,0,0,0,7.365,23.6a5.47,5.47,0,0,0,1.961-.466A6.6,6.6,0,0,0,14,24.8a6.6,6.6,0,0,0,4.675-1.666,5.462,5.462,0,0,0,1.961.466,2.259,2.259,0,0,0,2.124-2.406,3.3,3.3,0,0,0,3.113-1.61A2,2,0,0,0,26,18.811a2.041,2.041,0,0,0-1.517-1.881C23.181,16.6,21.2,15.2,21.2,14h.042c.322,0,2.358-.072,2.358-1.926,0-1.591-1.916-1.675-2.324-1.675H21.2V9.2S21.2,2,14,2Z"
                      transform="translate(-1.999 -2)"
                      fill="#272c30"
                    />
                  </svg>
                </div>

                <div
                  v-if="socialmedia.instagram"
                  class="mysvg"
                  @click="redirect(socialmedia.instagram)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="icons8_Instagram"
                      d="M9.667,3A6.667,6.667,0,0,0,3,9.667V20.333A6.667,6.667,0,0,0,9.667,27H20.333A6.667,6.667,0,0,0,27,20.333V9.667A6.667,6.667,0,0,0,20.333,3ZM23,5.667A1.333,1.333,0,1,1,21.667,7,1.334,1.334,0,0,1,23,5.667ZM15,8.333A6.667,6.667,0,1,1,8.333,15,6.667,6.667,0,0,1,15,8.333ZM15,11a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z"
                      transform="translate(-3 -3)"
                      fill="#272c30"
                    />
                  </svg>
                </div>

                <div
                  v-if="socialmedia.tiktok"
                  class="mysvg"
                  @click="redirect(socialmedia.tiktok)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="icons8_tiktok"
                      d="M7,3A4.02,4.02,0,0,0,3,7V23a4.02,4.02,0,0,0,4,4H23a4.02,4.02,0,0,0,4-4V7a4.02,4.02,0,0,0-4-4Zm8,5.333h2.667c0,1.34,1.961,2.667,2.667,2.667v2.667a5.066,5.066,0,0,1-2.667-.953v4.953a4,4,0,1,1-4-4v2.667A1.333,1.333,0,1,0,15,17.667Z"
                      transform="translate(-3 -3)"
                      fill="#272c30"
                    />
                  </svg>
                </div>

                <div
                  v-if="socialmedia.whatsapp"
                  class="mysvg"
                  @click="redirect(socialmedia.whatsapp)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20.001"
                    viewBox="0 0 24 24.001"
                  >
                    <path
                      id="icons8_whatsapp"
                      d="M22.493,5.514A11.989,11.989,0,0,0,3.626,19.973L2,26l6.28-1.484a11.977,11.977,0,0,0,5.728,1.459h0A11.989,11.989,0,0,0,22.493,5.514ZM19.878,18.266a3.021,3.021,0,0,1-2.022,1.423c-.55.05-1.064.248-3.594-.749a12.284,12.284,0,0,1-5.116-4.521,5.824,5.824,0,0,1-1.223-3.1,3.353,3.353,0,0,1,1.049-2.5,1.1,1.1,0,0,1,.8-.374c.2,0,.4,0,.574.007.214.008.45.019.674.517.266.593.848,2.074.923,2.224a.553.553,0,0,1,.025.524,2.079,2.079,0,0,1-.3.5c-.15.175-.314.39-.449.524-.15.149-.306.312-.132.611a9.01,9.01,0,0,0,1.666,2.074,8.228,8.228,0,0,0,2.409,1.487c.3.15.474.125.649-.076s.749-.874.948-1.174.4-.25.674-.15,1.747.824,2.046.974.5.224.574.349A2.507,2.507,0,0,1,19.878,18.266Z"
                      transform="translate(-2 -2)"
                      fill="#272c30"
                    />
                  </svg>
                </div>

                <div
                  v-if="socialmedia.telegram"
                  class="mysvg"
                  @click="redirect(socialmedia.telegram)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20.624"
                    viewBox="0 0 24 21.624"
                  >
                    <path
                      id="icons8_telegram_app"
                      d="M23.965,2.985a2.925,2.925,0,0,0-.945.225c-.258.1-1.186.492-2.663,1.111s-3.434,1.441-5.52,2.318L3.574,11.379a2.877,2.877,0,0,0-.788.42A2.114,2.114,0,0,0,2,13.369a2.081,2.081,0,0,0,.6,1.411,2.687,2.687,0,0,0,.935.574v0c.936.373,3.732,1.492,4.4,1.758.236.707,1.25,3.737,1.491,4.5h0a3.563,3.563,0,0,0,.567,1.184,1.635,1.635,0,0,0,.422.384,1.391,1.391,0,0,0,.209.1c.01,0,.02.005.03.009l-.028-.007c.021.008.04.021.061.028a.815.815,0,0,0,.105.021,1.67,1.67,0,0,0,.481.084,1.687,1.687,0,0,0,1.053-.354l.026-.019,2.818-2.384,3.45,3.193a2.02,2.02,0,0,0,1.7.757,2.2,2.2,0,0,0,1.566-.692,2.272,2.272,0,0,0,.656-1.207l0,0c.077-.4,3.38-16.983,3.38-16.983l-.007.028A2.772,2.772,0,0,0,25.925,4.4a1.944,1.944,0,0,0-.844-1.125A1.981,1.981,0,0,0,23.965,2.985Zm-.473,2.628c-.131.655-3.089,15.521-3.272,16.453l-4.983-4.611L11.868,20.3l.933-3.647s6.436-6.515,6.823-6.894c.312-.3.377-.41.377-.516A.218.218,0,0,0,19.762,9a1.108,1.108,0,0,0-.462.211c-1.373.856-7.219,4.187-10.093,5.823-.173-.069-2.771-1.112-4.17-1.671L15.768,8.852c2.086-.877,4.042-1.7,5.518-2.318C22.532,6.011,23.181,5.741,23.492,5.612ZM20.184,22.236h0l0,.007C20.186,22.236,20.183,22.243,20.184,22.236Z"
                      transform="translate(-2.004 -2.983)"
                      fill="#272c30"
                    />
                  </svg>
                </div>

                <div
                  v-if="socialmedia.linkedin"
                  class="mysvg"
                  @click="redirect(socialmedia.linkedin)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <path
                      id="icons8_linkedin"
                      d="M20.778,3H5.222A2.222,2.222,0,0,0,3,5.222V20.778A2.222,2.222,0,0,0,5.222,23H20.778A2.222,2.222,0,0,0,23,20.778V5.222A2.222,2.222,0,0,0,20.778,3ZM9.667,18.556h-2.8V10.778h2.8Zm-1.451-9.2A1.328,1.328,0,0,1,6.787,8.019,1.358,1.358,0,0,1,8.31,6.686,1.328,1.328,0,0,1,9.739,8.019,1.358,1.358,0,0,1,8.216,9.352Zm11.451,9.2H16.953V14.3c0-1.176-.723-1.447-.994-1.447s-1.176.181-1.176,1.447v4.251h-2.8V10.778h2.8v1.086a2.535,2.535,0,0,1,2.441-1.086c1.357,0,2.442,1.086,2.442,3.527Z"
                      transform="translate(-3 -3)"
                      fill="#272c30"
                    />
                  </svg>
                </div>


                  <div
                    v-if="socialmedia.facebook"
                    class="mysvg"
                    @click="redirect(socialmedia.facebook)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 12.63 24"
                      
                    >
                      <path
                        id="icons8_facebook_f"
                        d="M19.63,10.4H15.4V8c0-1.238.1-2.018,1.876-2.018h2.242V2.166A31.278,31.278,0,0,0,16.232,2,5.249,5.249,0,0,0,10.6,7.639V10.4H7v4.8h3.6V26h4.8V15.2h3.679Z"
                        transform="translate(-7 -2)"
                        fill="#272c30"
                      />
                    </svg>
                  </div>
             
                <a
                  v-if="socialmedia.phone"
                  :href="socialmedia.phone"
                  class="mysvg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="icons8_phone"
                      d="M25.7,19.651a20.1,20.1,0,0,1-2.565-.187,19.559,19.559,0,0,1-2.2-.567,1.356,1.356,0,0,0-1.359.337l-2.956,2.939a20.5,20.5,0,0,1-5.064-3.723,20.5,20.5,0,0,1-3.723-5.064l2.939-2.957A1.356,1.356,0,0,0,11.1,9.071a19.467,19.467,0,0,1-.565-2.2A20.089,20.089,0,0,1,10.349,4.3,1.333,1.333,0,0,0,9.013,3H4.333A1.323,1.323,0,0,0,3,4.333,22.906,22.906,0,0,0,9.621,20.379,22.906,22.906,0,0,0,25.667,27,1.323,1.323,0,0,0,27,25.667v-4.68A1.333,1.333,0,0,0,25.7,19.651Z"
                      transform="translate(-3 -3)"
                      fill="#272c30"
                    />
                  </svg>
                </a>

                <div
                  v-if="socialmedia.email"
                  class="mysvg"
                  @click="redirect(socialmedia.email)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                  >
                    <path
                      id="icons8_envelope"
                      d="M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4Zm0,4-8,5L4,8V6l8,5,8-5Z"
                      transform="translate(-2 -4)"
                      fill="#272c30"
                    />
                  </svg>
                </div>
                <div
                  v-if="footerData.longitude || footerData.latitude"
                  class="mysvg"
                  @click="openMap(footerData.latitude, footerData.longitude)"
                >
               
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 36 47">
  <path id="icons8_Location" d="M24,1C15.2,1,6.015,7.988,6,18,5.982,29.981,24,48,24,48S42.019,30.006,42,18C41.984,8,32.8,1,24,1Zm0,25a8,8,0,1,1,8-8A8,8,0,0,1,24,26Z" transform="translate(-6 -1)" fill="#272c30"/>
</svg>

                </div>
              </div>
            </b-card-text>
          </div>
        </div>
      </div>
    </div>
    <div class="my-1">
      <b-card-title class="mb-25 font-weight-bold color-dark">
        {{ getCookie("lang") === "en" ? "SmartLife" : "سمارت لايف"
        }}{{ currentYear }}
      </b-card-title>
    </div>
  </div>
</template>

<script>
import { BImg, BCardText, BCardTitle } from "bootstrap-vue";

export default {
  components: {
    BImg,
    BCardText,
    BCardTitle,
  },
  props: {
    footerData: {
      type: [Array, Object],
      default: () => [],
    },
    socialmedia: {
      type: [Array, Object],
      default: () => [],
    },
    num: {
      type: Number,
      default: () => [],
    },
  },
  data() {
    return {
      markerLatLng: [this.footerData.longitude, this.footerData.latitude],
    };
  },
  created() {},
  methods: {
    getCookie(name) {
      const nameEQ = `${name}=`;
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },

    redirect(icon) {
      window.open(`${icon}`);
    },
    openMap(lat, lng) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
      );
    },
    showMap() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>

<style scoped>
.my-gap {
  gap: 20px;
}
</style>

<template>
  <div>
    <b-card
      id="show-btn"
      class="card-developer-meetup-card-2"
      @click="showModal"
    >
      <div class="rounded-md position-relative card-height row">
        <b-card
          :img-src="item.image"
          img-alt="card img"
          class="position-static py-0 my-0 col-5 bg-transparent"
        />
        <div class="col-7 align-self-center">
          <div class="">
            <b-card-title class="mb-25 font-weight-bold color-dark">
              {{
                getCookie("lang") == "en"
                  ? item.english_name || item.product_name
                  : item.product_name
              }}
            </b-card-title>
            <b-card-text class="mb-0">
              {{ cutstring }}
              
               <span
            v-if="cutstring"
              class="color-primary"
              @click="showModal"
            >   {{
              getCookie("lang") == "en" ?"show more"||"عرض المزيد":
              " عرض المزيد"

            }}</span>
            
            </b-card-text>
          </div>
          <div class="d-flex">
            <b-card-text class="mt-2 font-weight-bold color-primary">
              {{ item.price }} {{ item.currency }}
            </b-card-text>
          </div>
        </div>
      </div>
    </b-card>

    <!-- modal -->
    <b-modal
      ref="my-modal"
      hide-footer
      :title=' getCookie("lang") === "en" ? "Details" : "التفاصيل" '
      centered
      size="sm"
      header-bg-variant="white"
      header-text-variant="dark"
    >
      <div class="rounded position-relative">
        <b-card
          :img-src="item.image"
          img-alt="card img"
          rounded
          class="position-static py-0 my-0 bg-transparent shadow-none"
        />

        <div class="d-flex">
          <b-card-text class="mt-2 font-weight-bold color-primary">
            {{ item.price }} {{ item.currency }}
          </b-card-text>
        </div>

        <div class="">
          <b-card-title class="mb-25 font-weight-bold color-dark">
             {{
              getCookie("lang") == "en"
                ? item.english_name || item.product_name
                : item.product_name
            }}
          </b-card-title>
          <b-card-text
            class="mb-0"
            
            v-html="getCookie('lang') === 'en' ? item.english_description : item.description"
          >
            <!-- {{ item.description }} -->
          </b-card-text>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      card_style: 1,
    }
  },
  computed: {
    cutstring() {
      if (this.getCookie('lang') === 'en') {
        if (this.item.english_description != null) {
          return this.item.english_description.substring(0, 60) 
        }

        return null
      }

      if (this.item.description != null) {
        return this.item.description.substring(0, 60) 

      }

      return null
    },
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },

    getCookie(name) {
      const nameEQ = `${name}=`
      const ca = document.cookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
      }
      return null
    },
  },
}
</script>

<style scoped>
.card-height {
  height: 160px;
}
</style>

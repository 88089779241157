<template>
  <div class="theme_3">
    <b-card
      class="profile-header mb-2 position-relative"
      :img-src="headerData.cover"
      img-top
      overlay
      text-variant="white"
      body-class="bg-overlay "
      alt="cover photo"
    >
      <div
        class="d-flex justify-content-end align-items-center app-content2 content"
        style="min-height: auto"
      >
        <div class="header_img">
          <div class="profile-img-container">
            <div class="d-flex justify-content-between">
              <b-card-text
                class="align-items-center border-0 btn d-flex info-topic mx-sm p-0 font-weight-bold"
              >
                <div
                  v-if="lang == 'en'"
                  class="mx-sm d-flex justify-content-center align-items-center"
                  @click="changeLang('ar')"
                >
                  <div class="info-topic2 text-white">اللغة العربية</div>
                  <div class="info-topic_sm text-white">ع</div>

                  <svg
                    id="Group_11535"
                    data-name="Group 11535"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="Path_66429"
                      data-name="Path 66429"
                      d="M0,0H24V24H0Z"
                      fill="none"
                    />
                    <circle
                      id="Ellipse_1445"
                      data-name="Ellipse 1445"
                      cx="9"
                      cy="9"
                      r="9"
                      transform="translate(3 3)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <line
                      id="Line_39"
                      data-name="Line 39"
                      x2="16.8"
                      transform="translate(3.6 9)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <line
                      id="Line_40"
                      data-name="Line 40"
                      x2="16.8"
                      transform="translate(3.6 15)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Path_66430"
                      data-name="Path 66430"
                      d="M11.5,3a17,17,0,0,0,0,18"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Path_66431"
                      data-name="Path 66431"
                      d="M12.5,3a17,17,0,0,1,0,18"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>
                <div
                  v-else
                  class="mx-sm d-flex justify-content-center align-items-center"
                  @click="changeLang('en')"
                >
                  <div class="info-topic2 text-white">English</div>
                  <div class="info-topic_sm text-white">E</div>
                  <svg
                    id="Group_11535"
                    data-name="Group 11535"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="Path_66429"
                      data-name="Path 66429"
                      d="M0,0H24V24H0Z"
                      fill="none"
                    />
                    <circle
                      id="Ellipse_1445"
                      data-name="Ellipse 1445"
                      cx="9"
                      cy="9"
                      r="9"
                      transform="translate(3 3)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <line
                      id="Line_39"
                      data-name="Line 39"
                      x2="16.8"
                      transform="translate(3.6 9)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <line
                      id="Line_40"
                      data-name="Line 40"
                      x2="16.8"
                      transform="translate(3.6 15)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Path_66430"
                      data-name="Path 66430"
                      d="M11.5,3a17,17,0,0,0,0,18"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Path_66431"
                      data-name="Path 66431"
                      d="M12.5,3a17,17,0,0,1,0,18"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>
              </b-card-text>
            </div>
            <!--/ profile title -->
          </div>
        </div>
      </div>

      <b-card class="insideCard">
        <div class="profile-img3 d-flex flex-column align-items-center">
          <b-img :src="headerData.logo" rounded fluid alt="profile photo" />
          <!-- profile title -->
          <h3 class="profile-title d-flex font-weight-bold px-1 center">
            {{
              getCookie("lang") == "en"
                ? headerData.en_restaurant_name
                : headerData.ar_restaurant_name
            }}
          </h3>
        </div>

        <div>
          <b-card-text class="mt-1 mb-0">
            <div class="d-flex flex-wrap my-gap justify-content-center mt-5">
              <div
                v-if="socialmedia.twitter"
                class="mt-1 mysvg3"
                @click="redirect(socialmedia.twitter)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 48 48"
                >
                  <g
                    id="Group_14449"
                    data-name="Group 14449"
                    transform="translate(-1071 -936)"
                  >
                    <circle
                      id="Ellipse_1527"
                      data-name="Ellipse 1527"
                      cx="24"
                      cy="24"
                      r="24"
                      transform="translate(1071 936)"
                      :fill="headerData.theme_color"
                    />
                    <path
                      id="icons8_Twitter"
                      d="M26,4A15.79,15.79,0,0,1,22.082,5.53c-.032.008-.059.019-.09.028A5.4,5.4,0,0,0,12.8,9.4c0,.157-.013.446,0,.6A11.862,11.862,0,0,1,3.518,5.2a7.307,7.307,0,0,0-.343,2.438c0,1.681,1.314,3.332,3.36,4.356a4.919,4.919,0,0,1-1.224.167A3.223,3.223,0,0,1,3.2,11.42v.061c0,2.35,2.494,3.949,4.711,4.394a4.3,4.3,0,0,1-1.8.292c-.312,0-1.416-.143-1.711-.2.617,1.926,2.842,3.008,4.962,3.047C7.7,20.317,6.553,20.8,3.157,20.8H2a14.546,14.546,0,0,0,7.616,2.4C18.532,23.2,23.6,16.4,23.6,10c0-.1,0-.319-.006-.536,0-.022.006-.042.006-.064s-.01-.064-.01-.1c0-.163-.007-.316-.011-.395A9.861,9.861,0,0,0,26,6.4a9.684,9.684,0,0,1-2.784.763A7.269,7.269,0,0,0,26,4Z"
                      transform="translate(1081 946.001)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </div>

              <div
                v-if="socialmedia.snapchat"
                class="mt-1 mysvg3"
                @click="redirect(socialmedia.snapchat)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 48 48"
                >
                  <g
                    id="Group_14450"
                    data-name="Group 14450"
                    transform="translate(-1023 -948)"
                  >
                    <circle
                      id="Ellipse_1528"
                      data-name="Ellipse 1528"
                      cx="24"
                      cy="24"
                      r="24"
                      transform="translate(1023 948)"
                      :fill="headerData.theme_color"
                    />
                    <path
                      id="icons8_snapchat"
                      d="M14,2C6.8,2,6.8,9.2,6.8,9.2v1.2H6.724c-.408,0-2.324.084-2.324,1.675C4.4,13.927,6.436,14,6.758,14H6.8c0,1.2-1.982,2.605-3.283,2.93A2.041,2.041,0,0,0,2,18.811a2,2,0,0,0,.127.772,3.3,3.3,0,0,0,3.113,1.61A2.26,2.26,0,0,0,7.365,23.6a5.47,5.47,0,0,0,1.961-.466A6.6,6.6,0,0,0,14,24.8a6.6,6.6,0,0,0,4.675-1.666,5.462,5.462,0,0,0,1.961.466,2.259,2.259,0,0,0,2.124-2.406,3.3,3.3,0,0,0,3.113-1.61A2,2,0,0,0,26,18.811a2.041,2.041,0,0,0-1.517-1.881C23.181,16.6,21.2,15.2,21.2,14h.042c.322,0,2.358-.072,2.358-1.926,0-1.591-1.916-1.675-2.324-1.675H21.2V9.2S21.2,2,14,2Z"
                      transform="translate(1033.001 959)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </div>

              <div
                v-if="socialmedia.instagram"
                class="mt-1 mysvg3"
                @click="redirect(socialmedia.instagram)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 48 48"
                >
                  <g
                    id="Group_14451"
                    data-name="Group 14451"
                    transform="translate(-1021 -948)"
                  >
                    <circle
                      id="Ellipse_1528"
                      data-name="Ellipse 1528"
                      cx="24"
                      cy="24"
                      r="24"
                      transform="translate(1021 948)"
                      :fill="headerData.theme_color"
                    />
                    <path
                      id="icons8_Instagram"
                      d="M9.667,3A6.667,6.667,0,0,0,3,9.667V20.333A6.667,6.667,0,0,0,9.667,27H20.333A6.667,6.667,0,0,0,27,20.333V9.667A6.667,6.667,0,0,0,20.333,3ZM23,5.667A1.333,1.333,0,1,1,21.667,7,1.334,1.334,0,0,1,23,5.667ZM15,8.333A6.667,6.667,0,1,1,8.333,15,6.667,6.667,0,0,1,15,8.333ZM15,11a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z"
                      transform="translate(1030 957)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </div>

              <div
                v-if="socialmedia.tiktok"
                class="mt-1 mysvg3"
                @click="redirect(socialmedia.tiktok)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 48 48"
                >
                  <g
                    id="Group_14451"
                    data-name="Group 14451"
                    transform="translate(-1021 -948)"
                  >
                    <circle
                      id="Ellipse_1528"
                      data-name="Ellipse 1528"
                      cx="24"
                      cy="24"
                      r="24"
                      transform="translate(1021 948)"
                      :fill="headerData.theme_color"
                    />
                    <path
                      id="icons8_Instagram"
                      d="M9.667,3A6.667,6.667,0,0,0,3,9.667V20.333A6.667,6.667,0,0,0,9.667,27H20.333A6.667,6.667,0,0,0,27,20.333V9.667A6.667,6.667,0,0,0,20.333,3ZM23,5.667A1.333,1.333,0,1,1,21.667,7,1.334,1.334,0,0,1,23,5.667ZM15,8.333A6.667,6.667,0,1,1,8.333,15,6.667,6.667,0,0,1,15,8.333ZM15,11a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z"
                      transform="translate(1030 957)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </div>

              <div
                v-if="socialmedia.whatsapp"
                class="mt-1 mysvg3"
                @click="redirect(socialmedia.whatsapp)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 48 48"
                >
                  <g
                    id="Group_14453"
                    data-name="Group 14453"
                    transform="translate(-1021 -943)"
                  >
                    <circle
                      id="Ellipse_1528"
                      data-name="Ellipse 1528"
                      cx="24"
                      cy="24"
                      r="24"
                      transform="translate(1021 943)"
                      :fill="headerData.theme_color"
                    />
                    <path
                      id="icons8_whatsapp"
                      d="M22.493,5.514A11.989,11.989,0,0,0,3.626,19.973L2,26l6.28-1.484a11.977,11.977,0,0,0,5.728,1.459h0A11.989,11.989,0,0,0,22.493,5.514ZM19.878,18.266a3.021,3.021,0,0,1-2.022,1.423c-.55.05-1.064.248-3.594-.749a12.284,12.284,0,0,1-5.116-4.521,5.824,5.824,0,0,1-1.223-3.1,3.353,3.353,0,0,1,1.049-2.5,1.1,1.1,0,0,1,.8-.374c.2,0,.4,0,.574.007.214.008.45.019.674.517.266.593.848,2.074.923,2.224a.553.553,0,0,1,.025.524,2.079,2.079,0,0,1-.3.5c-.15.175-.314.39-.449.524-.15.149-.306.312-.132.611a9.01,9.01,0,0,0,1.666,2.074,8.228,8.228,0,0,0,2.409,1.487c.3.15.474.125.649-.076s.749-.874.948-1.174.4-.25.674-.15,1.747.824,2.046.974.5.224.574.349A2.507,2.507,0,0,1,19.878,18.266Z"
                      transform="translate(1031 953)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </div>

              <div
                v-if="socialmedia.telegram"
                class="mt-1 mysvg3"
                @click="redirect(socialmedia.telegram)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 48 48"
                >
                  <g
                    id="Group_14454"
                    data-name="Group 14454"
                    transform="translate(-1021 -943)"
                  >
                    <circle
                      id="Ellipse_1528"
                      data-name="Ellipse 1528"
                      cx="24"
                      cy="24"
                      r="24"
                      transform="translate(1021 943)"
                      :fill="headerData.theme_color"
                    />
                    <path
                      id="icons8_telegram_app"
                      d="M23.965,2.985a2.925,2.925,0,0,0-.945.225c-.258.1-1.186.492-2.663,1.111s-3.434,1.441-5.52,2.318L3.574,11.379a2.877,2.877,0,0,0-.788.42A2.114,2.114,0,0,0,2,13.369a2.081,2.081,0,0,0,.6,1.411,2.687,2.687,0,0,0,.935.574v0c.936.373,3.732,1.492,4.4,1.758.236.707,1.25,3.737,1.491,4.5h0a3.563,3.563,0,0,0,.567,1.184,1.635,1.635,0,0,0,.422.384,1.391,1.391,0,0,0,.209.1c.01,0,.02.005.03.009l-.028-.007c.021.008.04.021.061.028a.815.815,0,0,0,.105.021,1.67,1.67,0,0,0,.481.084,1.687,1.687,0,0,0,1.053-.354l.026-.019,2.818-2.384,3.45,3.193a2.02,2.02,0,0,0,1.7.757,2.2,2.2,0,0,0,1.566-.692,2.272,2.272,0,0,0,.656-1.207l0,0c.077-.4,3.38-16.983,3.38-16.983l-.007.028A2.772,2.772,0,0,0,25.925,4.4a1.944,1.944,0,0,0-.844-1.125A1.981,1.981,0,0,0,23.965,2.985Zm-.473,2.628c-.131.655-3.089,15.521-3.272,16.453l-4.983-4.611L11.868,20.3l.933-3.647s6.436-6.515,6.823-6.894c.312-.3.377-.41.377-.516A.218.218,0,0,0,19.762,9a1.108,1.108,0,0,0-.462.211c-1.373.856-7.219,4.187-10.093,5.823-.173-.069-2.771-1.112-4.17-1.671L15.768,8.852c2.086-.877,4.042-1.7,5.518-2.318C22.532,6.011,23.181,5.741,23.492,5.612ZM20.184,22.236h0l0,.007C20.186,22.236,20.183,22.243,20.184,22.236Z"
                      transform="translate(1028.996 953.017)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </div>

              <div
                v-if="socialmedia.linkedin"
                class="mt-1 mysvg3"
                @click="redirect(socialmedia.linkedin)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 48 48"
                >
                  <g
                    id="Group_14489"
                    data-name="Group 14489"
                    transform="translate(-1071 -936)"
                  >
                    <circle
                      id="Ellipse_1527"
                      data-name="Ellipse 1527"
                      cx="24"
                      cy="24"
                      r="24"
                      transform="translate(1071 936)"
                      :fill="headerData.theme_color"
                    />
                    <path
                      id="icons8_linkedin"
                      d="M20.778,3H5.222A2.222,2.222,0,0,0,3,5.222V20.778A2.222,2.222,0,0,0,5.222,23H20.778A2.222,2.222,0,0,0,23,20.778V5.222A2.222,2.222,0,0,0,20.778,3ZM9.667,18.556h-2.8V10.778h2.8Zm-1.451-9.2A1.328,1.328,0,0,1,6.787,8.019,1.358,1.358,0,0,1,8.31,6.686,1.328,1.328,0,0,1,9.739,8.019,1.358,1.358,0,0,1,8.216,9.352Zm11.451,9.2H16.953V14.3c0-1.176-.723-1.447-.994-1.447s-1.176.181-1.176,1.447v4.251h-2.8V10.778h2.8v1.086a2.535,2.535,0,0,1,2.441-1.086c1.357,0,2.442,1.086,2.442,3.527Z"
                      transform="translate(1082 947)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </div>

              <div>
                <div
                  v-if="socialmedia.facebook"
                  class="mt-1 mysvg3"
                  @click="redirect(socialmedia.facebook)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 48 48"
                  >
                    <g
                      id="Group_14456"
                      data-name="Group 14456"
                      transform="translate(-1021 -943)"
                    >
                      <circle
                        id="Ellipse_1528"
                        data-name="Ellipse 1528"
                        cx="24"
                        cy="24"
                        r="24"
                        transform="translate(1021 943)"
                        :fill="headerData.theme_color"
                      />
                      <path
                        id="icons8_facebook_f"
                        d="M19.63,10.4H15.4V8c0-1.238.1-2.018,1.876-2.018h2.242V2.166A31.278,31.278,0,0,0,16.232,2,5.249,5.249,0,0,0,10.6,7.639V10.4H7v4.8h3.6V26h4.8V15.2h3.679Z"
                        transform="translate(1032 953)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <a
                v-if="socialmedia.phone"
                :href="socialmedia.phone"
                class="mt-1 mysvg3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 48 48"
                >
                  <g
                    id="Group_14457"
                    data-name="Group 14457"
                    transform="translate(-1021 -943)"
                  >
                    <circle
                      id="Ellipse_1528"
                      data-name="Ellipse 1528"
                      cx="24"
                      cy="24"
                      r="24"
                      transform="translate(1021 943)"
                      :fill="headerData.theme_color"
                    />
                    <path
                      id="icons8_phone"
                      d="M25.7,19.651a20.1,20.1,0,0,1-2.565-.187,19.559,19.559,0,0,1-2.2-.567,1.356,1.356,0,0,0-1.359.337l-2.956,2.939a20.5,20.5,0,0,1-5.064-3.723,20.5,20.5,0,0,1-3.723-5.064l2.939-2.957A1.356,1.356,0,0,0,11.1,9.071a19.467,19.467,0,0,1-.565-2.2A20.089,20.089,0,0,1,10.349,4.3,1.333,1.333,0,0,0,9.013,3H4.333A1.323,1.323,0,0,0,3,4.333,22.906,22.906,0,0,0,9.621,20.379,22.906,22.906,0,0,0,25.667,27,1.323,1.323,0,0,0,27,25.667v-4.68A1.333,1.333,0,0,0,25.7,19.651Z"
                      transform="translate(1030 952)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </a>

              <div
                v-if="socialmedia.email"
                class="mt-1 mysvg3"
                @click="redirect(socialmedia.email)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 48 48"
                >
                  <g
                    id="Group_14455"
                    data-name="Group 14455"
                    transform="translate(-1021 -943)"
                  >
                    <circle
                      id="Ellipse_1528"
                      data-name="Ellipse 1528"
                      cx="24"
                      cy="24"
                      r="24"
                      transform="translate(1021 943)"
                      :fill="headerData.theme_color"
                    />
                    <path
                      id="icons8_mail"
                      d="M2.769,4A3.092,3.092,0,0,0,0,7.333V20.667A3.092,3.092,0,0,0,2.769,24H21.231A3.092,3.092,0,0,0,24,20.667V7.333A3.092,3.092,0,0,0,21.231,4ZM.721,7.16,12,14.486,23.279,7.16l-7.154,6.7,5.913,7.292-7.212-6.181L12,17.16,9.173,14.972,1.962,21.153l5.913-7.292Z"
                      transform="translate(1033 953)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </div>

              <div
                class="mt-1 mysvg3"
                v-if="headerData.longitude || headerData.latitude"
                @click="openMap(headerData.latitude, headerData.longitude)"
              >
             
             
<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 48 48">
  <g id="Group_14473" data-name="Group 14473" transform="translate(-1147 -506)">
    <g id="Group_14489" data-name="Group 14489" transform="translate(-12323 -1320)">
      <g id="Group_14490" data-name="Group 14490" transform="translate(12449 883)">
        <circle id="Ellipse_1528" data-name="Ellipse 1528" cx="24" cy="24" r="24" transform="translate(1021 943)"   :fill="headerData.theme_color" />
      </g>
    </g>
    <path id="icons8_Location" d="M16,1C11.111,1,6.008,4.568,6,9.681,5.99,15.8,16,25,16,25S26.011,15.812,26,9.681C25.991,4.574,20.889,1,16,1Zm0,12.766a4.276,4.276,0,0,1-4.444-4.085A4.276,4.276,0,0,1,16,5.6a4.276,4.276,0,0,1,4.444,4.085A4.276,4.276,0,0,1,16,13.766Z" transform="translate(1155 517)" fill="#fff"/>
  </g>
</svg>

              </div>
            </div>
          </b-card-text>
        </div>

        <div class="rounded text-center center position-relative">
          <div class="app-content2 content mt-1">
            <div class="">
              <h4
                class="mb-25 font-weight-bold"
                v-show="
                  headerData.en_description != null ||
                  headerData.ar_description != null
                "
              >
                {{
                  getCookie("lang") === "en"
                    ? "About the restaurant"
                    : "حول المطعم "
                }}
              </h4>
            </div>

            <div>
              <b-card-text
                class="mb-0 description"
                v-html="
                  getCookie('lang') === 'en'
                    ? headerData.en_description
                    : headerData.ar_description
                "
              />
            </div>

            <div class="mt-1">
              <h4
                class="mb-25 font-weight-bold"
                v-if="headerData.open_at != null || headerData.close_at != null"
              >
                {{ getCookie("lang") === "en" ? "Time" : "  أوقات الدوام" }}
              </h4>

              <!-- <div>{{ headerData.open_at }} - {{ headerData.close_at }}</div> -->
              <div
                class=""
                v-if="headerData.open_at != null || headerData.close_at != null"
              >
                {{ getCookie("lang") === "en" ? "From" : "من" }}
                {{ headerData.open_at }}
                {{ getCookie("lang") === "en" ? "To" : "الى" }}
                {{ headerData.close_at }}
                {{
                  getCookie("lang") === "en"
                    ? headerData.en_time_description
                    : headerData.ar_time_description
                }}
              </div>
            </div>

            <div class="mt-1">
              <h4
                class="mb-25 font-weight-bold"
                v-if="
                  headerData.en_address != null || headerData.address != null
                "
              >
                {{ getCookie("lang") === "en" ? "Address" : "العنوان" }}
              </h4>

              <b-card-text class="mb-25">
                {{
                  getCookie("lang") === "en"
                    ? headerData.en_address
                    : headerData.address
                }}
              </b-card-text>
            </div>
          </div>
        </div>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import { BCard, BImg, BButton, BLink, BFormGroup } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BButton,
    BLink,
    BImg,
    // vSelect,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: [Array, Object],
      default: () => [],
    },
    socialmedia: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      lang: "ar",
    };
  },
  created() {
    this.lang = this.getCookie("lang");
  },
  methods: {
    // showModal() {
    //   this.$refs['my-modal'].show()
    // },
    // hideModal() {
    //   this.$refs['my-modal'].hide()
    // },
    redirect(icon) {
      // window.location.assign(`${icon}`)
      window.open(`${icon}`);
    },

    openMap(lat, lng) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
      );
    },

    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
      }
      document.cookie = `${name}=${value || ""}${expires}; path=/`;
    },
    getCookie(name) {
      const nameEQ = `${name}=`;
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    changeLang(lang) {
      this.setCookie("lang", (this.lang = lang));
      location.reload();
    },
  },
};
</script>

<style lang="scss">
.card .insideCard {
  width: 84%;
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
.my-gap {
  gap: 12px;
}
.center {
  color: #041724;
}
.description {
  display: block;
  height: auto;
  max-height: 66px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ebeded;
  z-index: 1;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  position: relative;
  background: var(--primary);
  z-index: 1;
  border-radius: 3px;
}
</style>

<template>
  <div>
    <div v-scroll="onScroll">
      <div
        v-if="!isVisible"
        fab
        fixed
        bottom
        right
        color="primary"
        @click="toBottom"
      >
        <div
          class="scroll-prompt"
          scroll-prompt=""
          ng-show="showPrompt"
          style="opacity: 1;"
        >
          <div class="scroll-prompt-arrow-container">
            <div class="scroll-prompt-arrow"><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24.692"
              height="16.499"
              viewBox="0 0 32.692 25.499"
            >
              <path
                id="icons8_less_than"
                d="M23.627,0a1.817,1.817,0,0,0-.972.319L.811,14.854a1.817,1.817,0,0,0,0,3.027l21.8,14.506a1.817,1.817,0,1,0,2.016-3.023L5.1,16.366,24.667,3.343A1.817,1.817,0,0,0,23.627,0Z"
                transform="translate(0 25.499) rotate(-90)"
                fill="#cedce0"
                opacity="0.5"
              />
            </svg>
            </div>
            <div class="scroll-prompt-arrow"><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24.692"
              height="16.499"
              viewBox="0 0 32.692 25.499"
            >
              <path
                id="icons8_less_than"
                d="M23.627,0a1.817,1.817,0,0,0-.972.319L.811,14.854a1.817,1.817,0,0,0,0,3.027l21.8,14.506a1.817,1.817,0,1,0,2.016-3.023L5.1,16.366,24.667,3.343A1.817,1.817,0,0,0,23.627,0Z"
                transform="translate(0 25.499) rotate(-90)"
                fill="#f1f5f9"
                opacity="0.74"
              />
            </svg>
            </div>
            <div class="scroll-prompt-arrow"><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24.692"
              height="16.499"
              viewBox="0 0 32.692 25.499"
            >
              <path
                id="icons8_less_than"
                d="M23.627,0a1.817,1.817,0,0,0-.972.319L.811,14.854a1.817,1.817,0,0,0,0,3.027l21.8,14.506a1.817,1.817,0,1,0,2.016-3.023L5.1,16.366,24.667,3.343A1.817,1.817,0,0,0,23.627,0Z"
                transform="translate(0 25.499) rotate(-90)"
                fill="#fff"
              />
            </svg>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
    }
  },
  methods: {
    onScroll() {
      this.isVisible = window.scrollY > 50
    },
    toBottom() {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style scoped>

</style>

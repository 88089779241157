<template>
  <div>
    <!-- {{ $t('message.value')}} -->
    <b-card
      class="profile-header mb-2 position-relative"
      :img-src="headerData.cover"
      img-top
      alt="cover photo"
      body-class="p-0 bg-secondary shadow rounded-bottom"
    >
      <div class="profile-img">
        <b-img
          :src="headerData.logo"
          rounded
          fluid
          alt="profile photo"
        />
      </div>
      <!-- profile picture -->
      <div class="position-relative profile-data">
        <div class="profile-img-container">
          <!-- profile title -->
          <div class="profile-title d-flex">
            <h2 class="">

              {{
                getCookie("lang") == "en"
                  ? headerData.en_restaurant_name
                  : headerData.ar_restaurant_name
              }}

            </h2>
          </div>

          <div class="d-flex justify-content-between color-dark">
            <!-- <div
              class="d-flex align-items-center color-dark mx-sm info-topic"
              @click="showModal"
                >
              <svg
                id="Group_11567"
                data-name="Group 11567"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                 >
                <path
                  id="Path_66477"
                  data-name="Path 66477"
                  d="M0,0H24V24H0Z"
                  fill="none"
                />
                <circle
                  id="Ellipse_1465"
                  data-name="Ellipse 1465"
                  cx="9"
                  cy="9"
                  r="9"
                  transform="translate(3 3)"
                  fill="none"
                  stroke="#2c3e50"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <line
                  id="Line_284"
                  data-name="Line 284"
                  x2="0.01"
                  transform="translate(12 8)"
                  fill="none"
                  stroke="#2c3e50"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_66478"
                  data-name="Path 66478"
                  d="M11,12h1v4h1"
                  fill="none"
                  stroke="#2c3e50"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
              </svg>
              <div class="mx-sm">
                {{
                  getCookie("lang") == "en"
                    ? 'About'
                    : 'عنا'
                }}
              </div>
            </div> -->

            <div
              class="align-items-center border-0 btn d-flex  mx-sm p-0 text-dark"
               >

              <div
                v-if="lang == 'en'"
                class="mx-sm d-flex justify-content-center align-items-center"
                @click="changeLang('ar')"
                 >
                <div class="info-topic2">
                  اللغة العربية
                </div>

                <div class="info-topic_sm">
                   ع
                </div>


                <svg
                  id="Group_11535"
                  data-name="Group 11535"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_66429"
                    data-name="Path 66429"
                    d="M0,0H24V24H0Z"
                    fill="none"
                  />
                  <circle
                    id="Ellipse_1445"
                    data-name="Ellipse 1445"
                    cx="9"
                    cy="9"
                    r="9"
                    transform="translate(3 3)"
                    fill="none"
                    stroke="#272c30"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <line
                    id="Line_39"
                    data-name="Line 39"
                    x2="16.8"
                    transform="translate(3.6 9)"
                    fill="none"
                    stroke="#272c30"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <line
                    id="Line_40"
                    data-name="Line 40"
                    x2="16.8"
                    transform="translate(3.6 15)"
                    fill="none"
                    stroke="#272c30"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Path_66430"
                    data-name="Path 66430"
                    d="M11.5,3a17,17,0,0,0,0,18"
                    fill="none"
                    stroke="#272c30"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Path_66431"
                    data-name="Path 66431"
                    d="M12.5,3a17,17,0,0,1,0,18"
                    fill="none"
                    stroke="#272c30"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
              <div
                v-else
                class="mx-sm d-flex justify-content-center align-items-center "
                @click="changeLang('en')"
                 >

                <div class="info-topic2">
                  English
                </div>
                  <div class="info-topic_sm">
                  E
                </div>
                <svg
                  id="Group_11535"
                  data-name="Group 11535"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_66429"
                    data-name="Path 66429"
                    d="M0,0H24V24H0Z"
                    fill="none"
                  />
                  <circle
                    id="Ellipse_1445"
                    data-name="Ellipse 1445"
                    cx="9"
                    cy="9"
                    r="9"
                    transform="translate(3 3)"
                    fill="none"
                    stroke="#272c30"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <line
                    id="Line_39"
                    data-name="Line 39"
                    x2="16.8"
                    transform="translate(3.6 9)"
                    fill="none"
                    stroke="#272c30"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <line
                    id="Line_40"
                    data-name="Line 40"
                    x2="16.8"
                    transform="translate(3.6 15)"
                    fill="none"
                    stroke="#272c30"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Path_66430"
                    data-name="Path 66430"
                    d="M11.5,3a17,17,0,0,0,0,18"
                    fill="none"
                    stroke="#272c30"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Path_66431"
                    data-name="Path 66431"
                    d="M12.5,3a17,17,0,0,1,0,18"
                    fill="none"
                    stroke="#272c30"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
            </div>
            <!-- <b-form-group>

              <select v-model="$i18n.locale">
                <option value="ar">
                  العربية
                </option>
                <option value="en">
                  English
                </option>
                <option value="fr">
                  France
                </option>
              </select>
            </b-form-group> -->
          </div>
          <!--/ profile title -->
        </div>
      </div>
      <!--/ profile picture -->
    </b-card>

    <!-- modal -->
    <!-- <b-modal
      ref="my-modal"
      hide-footer
      :title="getCookie('lang')==='en' ? 'About' : 'عنا' "
      centered
      size="sm"
      header-bg-variant="white"
      header-text-variant="dark"
        >
      <div class="rounded position-relative">
        <b-card-title class="text-center font-weight-bold">
          <div class="my-1">

            {{ getCookie('lang')==='en' ? headerData.en_restaurant_name : headerData.ar_restaurant_name }}
          </div>
        </b-card-title>
        <div>
          <b-card-title class="mb-25 font-weight-bold color-dark">
            <svg
              id="Group_11567"
              data-name="Group 11567"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                id="Path_66477"
                data-name="Path 66477"
                d="M0,0H24V24H0Z"
                fill="none"
              />
              <circle
                id="Ellipse_1465"
                data-name="Ellipse 1465"
                cx="9"
                cy="9"
                r="9"
                transform="translate(3 3)"
                fill="none"
                stroke="#2c3e50"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <line
                id="Line_284"
                data-name="Line 284"
                x2="0.01"
                transform="translate(12 8)"
                fill="none"
                stroke="#2c3e50"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Path_66478"
                data-name="Path 66478"
                d="M11,12h1v4h1"
                fill="none"
                stroke="#2c3e50"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </svg>

            {{ getCookie('lang')==='en' ? 'About the restaurant' : ' معلومات عنّا' }}
          </b-card-title>
        </div>

        <div>
          <b-card-text
            class="mb-0"
            v-html=" getCookie('lang')==='en' ? headerData.en_description : headerData.ar_description "
          />
        </div>

        <div class="mt-1">
          <b-card-title class="mb-25 font-weight-bold color-dark">
            <svg
              id="Group_11673"
              data-name="Group 11673"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                id="Path_66692"
                data-name="Path 66692"
                d="M0,0H24V24H0Z"
                fill="none"
              />
              <circle
                id="Ellipse_1490"
                data-name="Ellipse 1490"
                cx="9"
                cy="9"
                r="9"
                transform="translate(3 3)"
                fill="none"
                stroke="#2c3e50"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Path_66693"
                data-name="Path 66693"
                d="M12,7v5l3,3"
                fill="none"
                stroke="#2c3e50"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </svg>
            {{ getCookie('lang')==='en' ? 'Time' : '  أوقات الدوام' }}

          </b-card-title>

          <div>{{ headerData.open_at }} - {{ headerData.close_at }}</div>
        </div>

        <div>
          <b-card-text class="mb-0">
            {{ getCookie('lang')==='en' ? headerData.en_time_description : headerData.ar_time_description }}
          </b-card-text>
        </div>

        <div class="mt-1">
          <b-card-title class="mb-25 font-weight-bold color-dark">
            <svg
              id="Group_11698"
              data-name="Group 11698"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                id="Path_66696"
                data-name="Path 66696"
                d="M0,0H24V24H0Z"
                fill="none"
              />
              <path
                id="Path_66697"
                data-name="Path 66697"
                d="M10,14a3.5,3.5,0,0,0,5,0l4-4a3.536,3.536,0,1,0-5-5l-.5.5"
                fill="none"
                stroke="#272c30"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Path_66698"
                data-name="Path 66698"
                d="M14,10a3.5,3.5,0,0,0-5,0L5,14a3.536,3.536,0,0,0,5,5l.5-.5"
                fill="none"
                stroke="#272c30"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </svg>
            {{ getCookie('lang')==='en' ? 'Social Media' : '  روابطنا على التواصل الاجتماعي' }}
          </b-card-title>
        </div>

        <div>
          <b-card-text class="mb-0">

            <div class="d-flex my-gap">
              <div>
                <div
                  v-if="socialmedia.facebook"
                  @click="redirect(socialmedia.facebook)"
                >
                  <svg
                    id="Group_11697"
                    data-name="Group 11697"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="Path_66694"
                      data-name="Path 66694"
                      d="M0,0H24V24H0Z"
                      fill="none"
                    />
                    <path
                      id="Path_66695"
                      data-name="Path 66695"
                      d="M7,10v4h3v7h4V14h3l1-4H14V8a1,1,0,0,1,1-1h3V3H15a5,5,0,0,0-5,5v2H7"
                      fill="none"
                      stroke="#272c30"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>
              </div>
              <div

                v-if="socialmedia.instagram"
                @click="redirect(socialmedia.instagram)"
              >
                <svg
                  id="Group_11699"
                  data-name="Group 11699"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_66699"
                    data-name="Path 66699"
                    d="M0,0H24V24H0Z"
                    fill="none"
                  />
                  <rect
                    id="Rectangle_5027"
                    data-name="Rectangle 5027"
                    width="16"
                    height="16"
                    rx="4"
                    transform="translate(4 4)"
                    fill="none"
                    stroke="#272c30"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <circle
                    id="Ellipse_1491"
                    data-name="Ellipse 1491"
                    cx="3"
                    cy="3"
                    r="3"
                    transform="translate(9 9)"
                    fill="none"
                    stroke="#272c30"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <line
                    id="Line_285"
                    data-name="Line 285"
                    y2="0.001"
                    transform="translate(16.5 7.5)"
                    fill="none"
                    stroke="#272c30"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
              <div

                v-if="socialmedia.snapchat"
                @click="redirect(socialmedia.snapchat)"
              >
                <svg
                  id="Group_11700"
                  data-name="Group 11700"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_66700"
                    data-name="Path 66700"
                    d="M0,0H24V24H0Z"
                    fill="none"
                  />
                  <path
                    id="Path_66701"
                    data-name="Path 66701"
                    d="M16.882,7.842a4.882,4.882,0,1,0-9.764,0c0,4.273-.213,6.409-4.118,8.118,2,.882,2,.882,3,3,3,0,4,2,6,2s3-2,6-2c1-2.118,1-2.118,3-3-3.906-1.709-4.118-3.845-4.118-8.118ZM3,15.961c4-2.118,4-4.118,1-7.118m17,7.118c-4-2.118-4-4.118-1-7.118"
                    fill="none"
                    stroke="#272c30"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </svg>

              </div>
              <div>
                <svg
                  id="Group_11701"
                  data-name="Group 11701"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_66702"
                    data-name="Path 66702"
                    d="M0,0H24V24H0Z"
                    fill="none"
                  />
                  <path
                    id="Path_66703"
                    data-name="Path 66703"
                    d="M22,4.01A17.943,17.943,0,0,1,19,5a3.789,3.789,0,0,0-4.38-.737A3.933,3.933,0,0,0,12,8V9A9.392,9.392,0,0,1,4,5S-.182,12.433,8,16a9.856,9.856,0,0,1-6,2,13.425,13.425,0,0,0,10.034,1.517,11,11,0,0,0,7.651-7.742,13.84,13.84,0,0,0,.5-3.753c0-.249,1.51-2.772,1.818-4.013Z"
                    fill="none"
                    stroke="#272c30"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
            </div>
          </b-card-text>
        </div>
      </div>
    </b-modal> -->
  </div>
</template>

<script>
import {
  BCard, BImg, BButton, BLink, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BButton,
    BLink,
    BImg,
    // vSelect,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: [Array, Object],
      default: () => [],
    },
    socialmedia: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      lang: 'ar',
    }
  },
  created() {
    this.lang = this.getCookie('lang')
  },
  methods: {
    // showModal() {
    //   this.$refs['my-modal'].show()
    // },
    // hideModal() {
    //   this.$refs['my-modal'].hide()
    // },
    redirect(icon) {
      window.location = `https://${icon}`
    },

    setCookie(name, value, days) {
      let expires = ''
      if (days) {
        const date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = `; expires=${date.toUTCString()}`
      }
      document.cookie = `${name}=${value || ''}${expires}; path=/`
    },
    getCookie(name) {
      const nameEQ = `${name}=`
      const ca = document.cookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
      }
      return null
    },
    changeLang(lang) {
      this.setCookie('lang', this.lang = lang)
      location.reload()
    },
    // langChange(event) {
    //   localStorage.setItem('lang', event.target.value)
    //   window.location.reload()
    // },
  },
}
</script>

<style lang="scss">
// @import '@core/scss/vue/libs/vue-select.scss';
// .my-gap {
//   gap: 6px;
// }

// select {
//     width: 150px;
//     line-height: 49px;
//     height: 38px;
//     font-size: 22px;
//     outline: 0;
//     margin-bottom: 15px;
// }
</style>

<template>
    <b-card-title class="topic">{{ topic }}</b-card-title>
</template>

<script>
import {
  BCardHeader, BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {

    BCardHeader, BCardTitle,
  },
  props: {
    topic: String,
  },
}
</script>

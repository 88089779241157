<template>
  <div class="position-relative">
    <b-tabs
      id="main_tab"
      ref="my-tabs"
      v-model="tabIndex"
      no-key-nav
      nav-class="scrollable-tabs-nav"
      class="main try"
    >
    
      <b-tab
        v-for="(a, index) in categories"
        :key="index"
        :title="getCookie('lang') === 'en' ? a.en_name : a.ar_name"
        title-item-class="text-nowrap"
      
      >
        <b-tabs class="mt-2 nested-tab">
          <b-tab
            v-for="(i, index) in sub_categories.filter(
              (s) => s.parent_id == a.c_id
            )"
            :key="index"
            :title="
              getCookie('lang') === 'en' ? i.en_name || i.ar_name : i.ar_name
            "
          >
            <div class="my-2">
              <!-- <b-row v-if="cardData.success === true" class="match-height">
                <b-col
                  v-for="(n, i) in products.filter(
                    (e) => i.c_id == e.subcategory_id
                  )"
                  :key="i"
                  lg="3"
                  md="6"
                  :style="col_style"
                >
                  <card v-if="card_style == 1" :item="n" />
                  <card2 v-else-if="card_style == 2" :item="n" />

                  <card3 v-else-if="card_style == 3" :item="n" />
                  <card v-else :item="n" />
                </b-col>
              </b-row> -->
              
               <b-row v-if="cardData.success === true && card_style == 1" class="match-height">
                <b-col
                  v-for="(n, i) in products.filter(
                    (e) => i.c_id == e.subcategory_id
                  )"
                  :key="i"
                  lg="3"
                  md="6"
                  :style="col_style"
                >
                  <card  :item="n" />
                </b-col>
              </b-row>

               <b-row v-else-if="cardData.success === true && card_style == 2" class="match-height">
                <b-col
                  v-for="(n, i) in products.filter(
                    (e) => i.c_id == e.subcategory_id
                  )"
                  :key="i"
                  lg="4"
                  md="6"
                  :style="col_style"
                >
                  <card2 :item="n" />

                </b-col>
              </b-row>

               <b-row v-else-if="cardData.success === true && card_style == 3" class="match-height">
                <b-col
                  v-for="(n, i) in products.filter(
                    (e) => i.c_id == e.subcategory_id
                  )"
                  :key="i"
                  lg="4"
                  md="6"
                  :style="col_style"
                >
                  <card3  :item="n" />
                </b-col>
              </b-row>
<div v-else>


               <b-row  v-if="cardData.success === true" class="match-height">
                <b-col
                  v-for="(n, i) in products.filter(
                    (e) => i.c_id == e.subcategory_id
                  )"
                  :key="i"
                  lg="3"
                  md="6"
                  :style="col_style"
                >
                  <card  :item="n" />
                </b-col>
              </b-row>
</div>
            </div>
          </b-tab>
          <!-- {{sub_categories.filter((s) => s.parent_id == a.c_id ).length }} -->
          <b-tab
            :title-link-class="{
              'd-none':
                sub_categories.filter((s) => s.parent_id == a.c_id).length <
                  1 ||
                !products.filter((e) => e.cat_id == a.c_id && !e.subcategory_id)
                  .length,
            }"
            :title="getCookie('lang') === 'en' ? 'Others' : 'اخرى'"
          >
            <!-- <b-row class="">
              <b-col
                v-for="(n, i) in products.filter((e) => e.cat_id == a.c_id)"
                v-if="!n.subcategory_id"
                :key="i"
                lg="3"
                md="6"
                sm="12"
                :style="col_style"
              >
                <card v-if="card_style == 1" :item="n" />
                <card3 v-else-if="card_style == 3" :item="n" />

                <card2 v-else-if="card_style == 2" :item="n" />
                <card v-else :item="n" />
              </b-col>
            </b-row> -->

             <b-row class="" v-if="card_style == 1">
              <b-col
                v-for="(n, i) in products.filter((e) => e.cat_id == a.c_id)"
                v-if="!n.subcategory_id"
                :key="i"
                lg="3"
                md="6"
                sm="12"
                :style="col_style"
              >
                <card  :item="n" />
              
              </b-col>
            </b-row>

             <b-row class="" v-else-if="card_style == 3">
              <b-col
                v-for="(n, i) in products.filter((e) => e.cat_id == a.c_id)"
                v-if="!n.subcategory_id"
                :key="i"
                lg="4"
                md="6"
                sm="12"
                :style="col_style"
              >
                <card3  :item="n" />

              </b-col>
            </b-row>

             <b-row class="" v-else-if="card_style == 2">
              <b-col
                v-for="(n, i) in products.filter((e) => e.cat_id == a.c_id)"
                v-if="!n.subcategory_id"
                :key="i"
                lg="4"
                md="6"
                sm="12"
                :style="col_style"
              >
              

                <card2  :item="n" />
                
              </b-col>
            </b-row>

             <b-row class="" v-else>
              <b-col
                v-for="(n, i) in products.filter((e) => e.cat_id == a.c_id)"
                v-if="!n.subcategory_id"
                :key="i"
                lg="3"
                md="6"
                sm="12"
                :style="col_style"
              >
              
                <card  :item="n" />
              </b-col>
            </b-row>

          </b-tab>

          <!-- Control buttons-->
          <div class="text-center tab-pagination">
            <b-button-group
              class="d-flex"
              :class="
                getCookie('lang') === 'en'
                  ? 'flex-column-reverse'
                  : 'flex-column'
              "
            >
              <b-button
                id="leftBtn"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="secondary"
                class="btn-icon rounded btn-xs"
                @click="
                  getCookie('lang') === 'en' ? toright() : toleft(),
                    getCookie('lang') === 'en' ? tabIndex++ : tabIndex--
                "
              >
                <svg
                  id="left_row"
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.811"
                  height="10.121"
                  viewBox="0 0 7.811 14.121"
                >
                  <path
                    id="Path_66425"
                    data-name="Path 66425"
                    d="M9,6l6,6L9,18"
                    transform="translate(-7.939 -4.939)"
                    fill="none"
                    :stroke="getCookie('lang') === 'en' ? rightSvg : leftSvg"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </svg>
              </b-button>
              <b-button
                id="rightBtn"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="secondary"
                class="btn-icon rounded btn-xs"
                @click="
                  getCookie('lang') === 'en' ? toleft() : toright(),
                    getCookie('lang') === 'en' ? tabIndex-- : tabIndex++
                "
              >
                <svg
                  id="right_row"
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.811"
                  height="10.121"
                  viewBox="0 0 7.811 14.121"
                >
                  <path
                    id="Path_66425"
                    data-name="Path 66425"
                    d="M15,6,9,12l6,6"
                    transform="translate(-8.25 -4.939)"
                    fill="none"
                    :stroke="getCookie('lang') === 'en' ? leftSvg : rightSvg"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </svg>
              </b-button>
            </b-button-group>

            <!-- <b-card-text>
        <small>Current Tab: {{ tabIndex }}</small>
      </b-card-text> -->
          </div>
        </b-tabs>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BButtonGroup,
  BButton,
  BCard,
  BTab,
  BTabs,
  BCardCode,
  BCol,
  BRow,
} from "bootstrap-vue";

import axios from "axios";
import Ripple from "vue-ripple-directive";

import topic from "./topic.vue";
import card from "./card.vue";
import card2 from "./card2.vue";
import card3 from "./card3.vue";
import nodata from "../error/ErrorNoData.vue";

export default {
  components: {
    BCardCode,
    BButtonGroup,
    BButton,
    BCard,
    BTab,
    BTabs,
    topic,
    card,
    BCol,
    BRow,
    nodata,
    card2,
    card3,
  },
  directives: {
    Ripple,
  },
  props: [
    "template_id",
    "template",
    "APIUrl",
    "categories",
    "settings",
    "sub_categories",
  ],
  data: () => ({
    tabIndex: 0,
    tabActive: 0,
    cardData: [],
    products: [],
    rightSvg: "#000",
    leftSvg: "#9294a2",

    // uik: 0,
    card_style: " ",
    cut: "",

    isMouseDown: false,
    startX: null,
    scrollLeft: null,
    navElement: null,

    col_style: "margin-bottom:16px;",

    // tab data
  }),

  created() {
    try {
      const { resturant } = this.$route.params;
      axios.post(`${this.APIUrl}/api/get_products/${resturant}`).then((res) => {
        this.products = res.data.data;
        this.cardData = res.data;
        console.log(this.cardData, "products");
        if (this.template) {
          this.card_style = this.template_id;

          // console.log(this.template_id, 'templatecard1')
        } else {
          this.card_style = this.settings.menu_id || 1;

          // console.log(this.card_style, 'templatecard')
        }
      });
    } catch (e) {
      console.error(e);
    }
  },
  mounted() {
    this.navElement = this.$refs["my-tabs"].$el.querySelector("ul");

    this.navElement.addEventListener("mousedown", this.onMouseDown);
    this.navElement.addEventListener("mouseup", this.onMouseUp);
    this.navElement.addEventListener("mousemove", this.onMouseMove);
    this.navElement.addEventListener("mouseleave", this.onMouseLeave);

    this.navElement.addEventListener("touchstart", this.onTouchStart);
    this.navElement.addEventListener("touchmove", this.onTouchMove);

    // document.addEventListener('keydown', this.handleKeyPress)
  },
  onBeforeDestroy() {
    this.navElement.removeEventListener("mousedown", this.onMouseDown);
    this.navElement.removeEventListener("mouseup", this.onMouseUp);
    this.navElement.removeEventListener("mousemove", this.onMouseMove);
    this.navElement.removeEventListener("mouseleave", this.onMouseLeave);

    this.navElement.removeEventListener("touchstart", this.onMouseDown);
    this.navElement.removeEventListener("touchmove", this.onMouseMove);

    // document.removeEventListener('keydown', this.handleKeyPress)
  },
  methods: {
    toright() {
      document.getElementById("rightBtn").disabled = false;
      // document.querySelector('#__BVID__50  div').scrollLeft += 100
      console.log(
        this.categories.filter((s) => s.parent_id === 0),
        this.tabIndex,
        "ttttt"
      );
      if (
        this.tabIndex ===
        this.categories.filter((s) => s.parent_id === 0).length - 1
      ) {
        this.rightSvg = "#9294a2";
        this.leftSvg = "#000";
      } else {
        this.rightSvg = "#000";
        this.leftSvg = "#000";
      }
    },

    toleft() {
      // document.querySelector('#__BVID__50  div').scrollLeft -= 100

      if (this.tabIndex <= 1) {
        document.getElementById("leftBtn").disabled = true;
        this.leftSvg = "#9294a2";

        this.rightSvg = "#000";
      } else {
        document.getElementById("leftBtn").disabled = false;
        this.leftSvg = "#000";

        this.rightSvg = "#000";
      }
    },

    onTouchStart(event) {
      this.startX = event.touches[0].pageX - this.navElement.offsetLeft;
      this.scrollLeft = this.navElement.scrollLeft;
    },
    onTouchMove(event) {
      event.preventDefault();
      const x = event.touches[0].pageX - this.navElement.offsetLeft;
      const speed = 4; // higher number means faster scrolling
      const walk = (x - this.startX) * speed;
      this.navElement.scrollLeft = this.scrollLeft - walk;
    },
    onMouseDown(event) {
      console.log("mouse start");
      this.isMouseDown = true;

      this.startX = event.pageX - this.navElement.offsetLeft;
      this.scrollLeft = this.navElement.scrollLeft;
    },
    onMouseUp(event) {
      console.log("mouse up");
      this.isMouseDown = false;
    },
    onMouseLeave(event) {
      console.log("mouse leave");
      this.isMouseDown = false;
    },
    onMouseMove(event) {
      console.log("mouse move");
      if (!this.isMouseDown) return;

      event.preventDefault();
      const x = event.pageX - this.navElement.offsetLeft;
      const speed = 1.5; // higher number means faster scrolling
      const walk = (x - this.startX) * speed;
      this.navElement.scrollLeft = this.scrollLeft - walk;
    },

    getCookie(name) {
      const nameEQ = `${name}=`;
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },

    // handleKeyPress(e) {
    //   if (e.keyCode === 37) { // Previous page
    //     console.log(this.tabIndex, 'next')
    //   } else if (e.keyCode === 39) { // Next page
    //     console.log(this.tabIndex, 'prev')
    //   }
    // },
  },
};
</script>

<style>
/* #main_tab> div:first-child {
  overflow: hidden;
} */
.tab-pagination {
  background: #fff;
  padding: 0 5px;
  /* left: 5px!important; */
}

.scrollable-tabs-nav {
  flex-wrap: nowrap;
  overflow: hidden;
  /* Might not be needed based on your styling */
  margin-bottom: -14px;
}
</style>
